@use "./variables.scss";
@use "./mixins.scss";

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font: inherit;
  &::-webkit-scrollbar {
    display: none;
  }
}

a:link,
a:visited {
  color: unset;
  text-decoration: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  min-width: 100vw;
  min-height: 100vh;
  font-family: "Manrope";
  letter-spacing: 1px;
  font-style: normal;
  line-height: normal;
  overflow-x: hidden;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

button,
a {
  cursor: pointer;
}

.wrapper {
  margin: 0 auto;
  min-height: 100vh;
  width: 90vw;
  @media (max-width: 1024px) {
    width: 100vw;
  }
}
