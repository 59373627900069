@use "../../../variables.scss";
@use "../../../mixins.scss";

.foundersSection {
  padding: 200px 0;
  background-color: variables.$color-1;
  position: relative;
  @include mixins.displayFlexProps(row, flex-start, space-between);
  &::before {
    content: "";
    background-color: variables.$color-1;
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: -10;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  h2 {
    color: variables.$white;
    font-weight: 500;
    line-height: 28px;
    margin-right: 100px;
  }
  .foundersSection__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    overflow: hidden;
    .foundersSection__founderContainer {
      .foundersSection__founderTopContainer {
        @include mixins.displayFlexProps(row, flex-end, unset);
        column-gap: 20px;
        .foundersSection__imageContainer {
          img {
          }
        }
        .foundersSection__nameAndTitleContainer {
          @include mixins.displayFlexProps(column, flex-start, unset);
          .foundersSection__founderName,
          .foundersSection__founderTitle {
            color: variables.$white;
          }
          .foundersSection__founderName {
            font-size: 50px;
            font-weight: 500;
            white-space: nowrap;
          }
          .foundersSection__founderTitle {
            font-size: 16px;
          }
        }
      }
      .foundersSection__founderBottomContainer {
        position: relative;
        padding-top: 20px;
        span {
          position: absolute;
          color: variables.$color-2;
          font-size: 100px;
          font-weight: 600;
          line-height: 100px;
        }
        h4 {
          margin: 16px 0 0 48px;
          color: variables.$white;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .foundersSection {
    padding: 200px 20px;
    .foundersSection__content {
      @include mixins.displayFlexProps(column, flex-start, unset);
      row-gap: 100px;
      .foundersSection__founderContainer {
        .foundersSection__founderTopContainer {
          .foundersSection__founderName {
            white-space: wrap;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .foundersSection {
    @include mixins.displayFlexProps(column, flex-start, unset);
    row-gap: 100px;
    .foundersSection__content {
      .foundersSection__founderContainer {
        .foundersSection__founderTopContainer {
          .foundersSection__founderName {
            white-space: wrap;
          }
        }
      }
    }
  }
}

@media (max-width: 426px) {
  .foundersSection {
    .foundersSection__content {
      .foundersSection__founderContainer {
        .foundersSection__founderTopContainer {
          @include mixins.displayFlexProps(column, flex-start, unset);
          .foundersSection__nameAndTitleContainer {
            .foundersSection__founderName {
              font-size: 40px;
              @media (max-width: 376px) {
                font-size: 28px;
              }
            }
          }
        }
      }
    }
  }
}
