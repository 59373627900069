@use "../../../variables.scss";
@use "../../../mixins.scss";

.aboutUsSection {
  .aboutUsSection__content {
    h2,
    p {
      color: variables.$black;
    }
    .aboutUsSection__topContainer,
    .aboutUsSection__bottomContainer {
      @include mixins.displayFlexProps(
        column,
        flex-start,
        unset
      );
      row-gap: 40px;
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .aboutUsSection__topContainer {
      margin-bottom: 100px;
      h2 {
        font-weight: 500;
      }
    }
    .aboutUsSection__bottomContainer {
      h3 {
        font-weight: 400;
      }

      .aboutUsSection__imagesContainer {
        width: 100%;
        display: grid;
        // grid-template-columns: repeat(3, 400px);
        grid-template-columns: repeat(
          auto-fit,
          minmax(400px, 1fr)
        );
        grid-template-rows: repeat(3, 400px);
        gap: 20px;
        justify-content: center;
        overflow-x: hidden;
        img {
          width: 400px;
          height: 400px;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .aboutUsSection {
    padding-top: 450px;
  }
}

@media (max-width: 1025px) {
  .aboutUsSection {
    padding: 350px 20px 0 20px;

    .aboutUsSection__content {
      .aboutUsSection__bottomContainer {
        .aboutUsSection__imagesContainer {
          grid-template-columns: 400px 400px;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .aboutUsSection {
    padding-top: 200px;
    .aboutUsSection__content {
      .aboutUsSection__bottomContainer {
        .aboutUsSection__imagesContainer {
          grid-template-columns: 400px 400px;
        }
      }
    }
  }
}

@media (max-width: 426px) {
  .aboutUsSection {
    padding-top: 200px;

    .aboutUsSection__content {
      .aboutUsSection__bottomContainer {
        .aboutUsSection__imagesContainer {
          grid-template-columns: 400px;
        }
      }
    }
  }
}
