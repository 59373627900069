@use "../../mixins.scss";
@use "../../variables.scss";

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.mainPagePopup {
  position: fixed;
  top: 0;
  left: 100vw;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: variables.$white;
  transition: all 0.8s ease;
  [class$="__content"] {
    width: 90vw;
    margin: 0 auto;
    padding-top: 44px;

    h2 {
      font-weight: 600;
      color: variables.$color-1;
    }

    .volunteerForm__messageContainer,
    .donatePopup__messageContainer,
    .needHelpForm__messageContainer {
      margin-bottom: 16px;
      span {
        font-size: 14px;
        color: white;
      }
      &.positiveMessage,
      &.negativeMessage {
        opacity: 0;
        animation: fadeInOut 7s backwards;
      }
      &.positiveMessage {
        span {
          color: variables.$color-1;
        }
      }
      &.negativeMessage {
        span {
          color: rgb(204, 14, 14);
        }
      }
    }

    [class$="__buttonContainer"] {
      @include mixins.displayFlexProps(row, center, flex-end);
      margin-bottom: 44px;
    }
  }
}
