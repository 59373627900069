@use "../../../mixins.scss";
@use "../../../variables.scss";

.heroSection {
  width: 100vw;
  height: fit-content;
  .heroSection__content {
    height: fit-content;
    z-index: -4;
    transform: translateX(-5vw);
    .heroSection__imagesContainer {
      height: auto;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;

      img {
        width: 100%;
      }
    }
    .heroSection__h1Container {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100vw;
      @include mixins.displayFlexProps(row, center, center);
      flex-wrap: wrap;
      h1 {
        display: block;
        background-color: variables.$white;
        padding: 32px 100px;
        white-space: nowrap;
        font-weight: 500;
        text-align: center;
        width: fit-content;
        max-width: 100vw;
        color: variables.$black;
      }
    }
  }
}

@media (max-width: 1024px) {
  .heroSection {
    .heroSection__content {
      transform: translateX(0vw);
      .heroSection__imagesContainer {
      }
      .heroSection__h1Container {
        h1 {
          padding: 24px 64px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .heroSection {
    .heroSection__content {
      .heroSection__imagesContainer {
        grid-template-columns: 1fr;
        #soldierImage,
        #womanImage {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 426px) {
  .heroSection {
    .heroSection__content {
      .heroSection__imagesContainer {
      }
      .heroSection__h1Container {
        h1 {
          padding: 16px 20px;
          font-size: 20px;
          white-space: wrap;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .heroSection {
    .heroSection__content {
      .heroSection__h1Container {
        h1 {
          padding: 16px 10px;
        }
      }
    }
  }
}
