@use "../../../variables.scss";
@use "../../../mixins.scss";
@use "../../../input.scss";

.donatePopup {
  .donatePopup__content {
    .donatePopup__cardsContainer {
      padding-top: 44px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 24px;
      height: fit-content;
      .donatePopup__card {
        padding: 0px 16px 20px 16px;
        background-color: variables.$grey;
        span {
          font-size: 80px;
          font-weight: 400;
          color: variables.$color-1;
        }
        ul {
          list-style: none;
          margin: 52px 0;
          li {
            @include mixins.displayFlexProps(row, center, space-between);
            margin-bottom: 10px;
            span {
              font-size: 16px;
              color: variables.$black;
            }
            .donatePopup__listItemLeft {
              font-weight: 600;
              margin-right: auto;
            }
            .donatePopup__listItemRight {
              font-weight: 400;
            }
          }
        }
        .donatePopup__copyButtonContainer {
          width: 100%;
          text-align: end;
          button {
            margin-left: auto;
          }
        }
      }
    }
  }
  .donatePopup__messageContainer {
    position: fixed;
    bottom: 5%;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    &.positiveMessage,
    &.negativeMessage {
      opacity: 0;
      animation: fadeInOut 5s backwards;
    }
    &.positiveMessage {
      span {
        color: variables.$color-1;
      }
    }
    &.negativeMessage {
      span {
        color: rgb(204, 14, 14);
      }
    }
  }
}

@media (max-width: 768px) {
  .donatePopup {
    .donatePopup__content {
      .donatePopup__cardsContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }
    }
  }
}
