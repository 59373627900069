@use "../../variables.scss";
@use "../../mixins.scss";

.footer {
  position: relative;
  padding: 100px 0 100px 0;
  background-color: variables.$color-1;
  &::before {
    content: "";
    position: absolute;
    background-color: variables.$color-1;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -10;
  }
  .footer__content {
    color: white;
    @include mixins.displayFlexProps(row, flex-start, space-between);
    .footer__textContainer {
      max-width: 25%;
      @include mixins.displayFlexProps(column, flex-start, unset);
      row-gap: 40px;
      .footer__summary {
        font-size: 14px;
      }
      .footer__allRightsReserved {
        font-size: 12px;
      }
    }

    .footer__contactsContaienr,
    .footer__linksContaienr,
    .footer__documentsContaienr {
      h4 {
        font-weight: 600;
      }
      ul {
        margin-top: 20px;
        list-style: none;
        @include mixins.displayFlexProps(column, flex-start, unset);
        row-gap: 20px;
        li {
          position: relative;
        }
        li,
        li a {
          @include mixins.displayFlexProps(row, center, flex-start);
          column-gap: 8px;
          span {
            font-size: 14px;
          }
        }
      }
    }

    .footer__linksContaienr,
    .footer__documentsContaienr {
      ul {
        li {
          &::after {
            content: "";
            position: absolute;
            background-color: variables.$white;
            width: 100%;
            height: 1px;
            bottom: -4px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .footer {
    padding: 100px 20px 24px 20px;
  }
}

@media (max-width: 768px) {
  .footer {
    .footer__content {
      display: grid;
      grid-template-columns: repeat(3, min-content);
      grid-template-rows: 1fr;
      row-gap: 100px;
      .footer__textContainer {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 4;
        max-width: unset;
      }
    }
  }
}

@media (max-width: 426px) {
  .footer {
    .footer__content {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, min-content);

      .footer__contactsContaienr {
        grid-row-start: 1;
      }

      .footer__linksContaienr {
        grid-row-start: 2;
      }

      .footer__documentsContaienr {
        grid-row-start: 3;
        grid-row-end: 4;
      }

      .footer__textContainer {
        grid-row-end: unset;
        grid-row-start: 4;
        grid-column-end: unset;
      }
    }
  }
}
