@use "../../../variables.scss";
@use "../../../mixins.scss";

.vacanciesSection {
  padding: 200px 0;
  h3 {
    font-weight: 500;
    margin-bottom: 40px;
    color: variables.$black;
  }
  .vacanciesSection__content {
    @include mixins.displayFlexProps(column, flex-start, unset);
    row-gap: 40px;

    span,
    li {
      color: variables.$black;
    }
    .vacanciesSection__vacancyContainer {
      position: relative;
      display: grid;
      grid-template-columns: min-content min-content 1fr;
      grid-template-rows: 1fr;
      align-items: center;
      width: 100%;
      height: 160px;
      &:first-child {
        height: 210px;
      }
      .vacanciesSection__imageContainer {
        height: fit-content;
        width: 200px;
      }

      span {
        margin-right: auto;
        font-size: 40px;
        font-weight: 500;
        width: fit-content;
        white-space: nowrap;
      }
      .vacanciesSection__specializationsContainer {
        ul {
          list-style: none;
          @include mixins.displayFlexProps(column, flex-end, unset);
          row-gap: 12px;
          li {
            font-size: 16px;
            font-weight: 400;
            white-space: nowrap;
          }
        }
      }
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        top: 100%;
        left: 0;
        background-color: variables.$black;
      }
    }
  }
}

@media (max-width: 1024px) {
  .vacanciesSection {
    padding: 200px 20px;
    .vacanciesSection__content {
      row-gap: 100px;
    }
  }
}

@media (max-width: 768px) {
  .vacanciesSection {
    .vacanciesSection__content {
      .vacanciesSection__vacancyContainer {
        justify-content: unset;
        column-gap: 40px;
        .vacanciesSection__imageContainer {
          width: 100px;
        }
        &:last-child {
          img {
            margin: 0;
          }
        }
        span {
          font-size: 28px;
          margin: 0;
          white-space: wrap;
        }
      }
    }
  }
}

@media (max-width: 426px) {
  .vacanciesSection {
    h3 {
      margin-bottom: 100px;
    }
    .vacanciesSection__content {
      row-gap: 100px;
      .vacanciesSection__vacancyContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, min-content);
        row-gap: 40px;
        height: fit-content;
        &:first-child {
          height: 440px;
          &::after {
            top: 100%;
          }
        }
        .vacanciesSection__imageContainer {
        }
        span {
          font-size: 28px;
          margin: 0;
        }
        .vacanciesSection__specializationsContainer {
          ul {
            align-items: flex-start;
            li {
            }
          }
        }
        &::after {
          top: 110%;
        }
      }
    }
  }
}
