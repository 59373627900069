@use "../../../variables.scss";
@use "../../../mixins.scss";

.benefactorsSection {
  padding: 200px 0;
  @media (max-width: 1024px) {
    padding: 200px 20px;
  }
  h2 {
    font-weight: 500;
    color: variables.$black;
  }
  .benefactorsSection__content {
    padding: 100px 0;
  }
}
