@use "../../../mixins.scss";
@use "../../../variables.scss";

.formPopup__content {
  form {
    padding: 44px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 44px;

    .volunteerForm__bottomContainer,
    .needHelpForm__bottomContainer {
      p {
        font-size: 12px;
        margin-bottom: 16px;
      }
      text-align: center;
      width: fit-content;
      margin: 44px auto 0 auto;
    }
  }
}
